import { api } from '../services/api';
import { onContentBtn } from '../functions/events';

onContentBtn(
    'post',
    'vote',
    async (btnEl, contentEl, postId) => {
        const vote = parseInt(btnEl.getAttribute('data-vote'));

        return api
            .post(
                `/posts/${postId}/votes`,
                {
                    vote,
                },
            )
            .then((response) => {
                console.log('response.data', response.data);
                const post = response.data.post;

                const voteButtons = contentEl.querySelectorAll('.post__vote-btn');
                voteButtons.forEach((btn) => {
                    console.log(btn, post.vote, btn.getAttribute('data-vote'));
                    if (post.vote && post.vote === parseInt(btn.getAttribute('data-vote'))) {
                        btn.classList.add('active');
                    } else {
                        btn.classList.remove('active');
                    }
                });

                contentEl.querySelector('.post__score').innerHTML = post.scoreHtml;

                contentEl.querySelector('.post__vote-btn--up .post__vote-btn__count').innerHTML = post.up;
                contentEl.querySelector('.post__vote-btn--down .post__vote-btn__count').innerHTML = post.down;
            });
    },
);
